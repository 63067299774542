<template>
  <button
    type="button"
    class="inline-flex bg-cl-transparent brdr-none relative"
    @click="toggleWishlistPanel"
    :aria-label="$t('Open wishlist')"
  >
    <i class="icon-schowek icon-size cl-alternative hidden-xs-and-tablet" />
    <i class="icon-schowek icon-size cl-light-gray visible-xs-and-tablet" />
    <span
      class="whishlist-count absolute flex center-xs middle-xs border-box py0 px2 h6 lh16 weight-700 cl-white bg-cl-silver"
      v-cloak
      v-show="getWishlistItemsCount"
    >
      {{ getWishlistItemsCount }}
    </span>
  </button>
</template>

<script>
import WishlistIcon from '@vue-storefront/core/compatibility/components/blocks/Header/WishlistIcon'

export default {
  mixins: [WishlistIcon],
  computed: {
    productsInWishlist () {
      return this.$store.state.wishlist.items
    }
  },
  mounted () {
    this.$store.dispatch('wishlist/load')
  }
}
</script>

<style lang="scss" scoped>
  .icon-size {
    font-size: 32px;
    @media (max-width: 768px) {
      font-size: 26px;
    }
  }
  .whishlist-count {
    top: -3px;
    left: 70%;
    background: #ff8100;
    min-width: 16px;
    min-height: 16px;
    border-radius: 10px;
    @media (max-width: 768px) {
      top: 10px;
      left: 55%;
    }
  }
</style>
